<template>
    <div>        <!-- 蒙版/遮罩层 -->
        <div class="Hander">
            <img src="../../assets/img/toutu.png" alt=""  class="User">
            <div>
                <img src="../../assets/img/liaojie.png" alt="" class="Unit" @click="website()">
            </div>
        </div>
        <div class="Bottom">
            <img src="../../assets/img/dajiang.png" alt=""  class="Rideo">
        </div>
        <div class="Dome">
            <LuckyGrid
                ref="LuckDraw"
                width="80vw"
                height="80vw"
                :blocks="blocks"
                :prizes="prizes"
                :button="btnConfig"
                :default-style="{
                    gutter: 5,
                    borderRadius: 15,
                    fontColor: '#333333',
                    fontSize: '12px',
                    textAlign: 'center',
                    background: '#fff',
                    shadow: '0 5 1 #ebf1f4'
                    }"
                    :activeStyle="{
                    background: 'linear-gradient(270deg, #FFDCB8, #FDC689)',
                    shadow: ''
                    }"
                    @start="startCallBack"
                    @end="endCallBack"
            />
        </div>
        <van-popup v-model="show" style="background:none">
            <div class="Over">
                <img src="../../assets/img/acquire.png" alt="" class="Bander">
                <p class="Grand">恭喜你抽到了<span class="Grand2">{{piglist }}</span></p>
                <img :src=url alt="" class="Senter">
                <img src="../../assets/img/location.png" alt="" class="Hiden"  @click="harvest()" v-if="prize_type<=6">
                <p v-if="prize_type>6" style="color:#FFFFFF">前往禾乡农场APP仓库中查看</p>
            </div>
        </van-popup>
        <van-popup v-model="none" style="background:none">
           <div class="Tizi">
                <div>
                    <img src="../../assets/img/header.png" alt="" class="Query">
                </div>
                <div class="Rsat">
                    <p class="name">姓名</p>
                    <input type="text" class="username" placeholder="请填写收货人名称" v-model="name">
                    <p class="name">手机号码</p>
                    <input type="text" class="username" placeholder="请填写收货人手机号码" v-model="username" >
                    <p class="name">所在地区</p>
                        <div class="master">
                            <div class="users"  @click="location()">
                                <p class="region" v-if="flag">请选择</p>
                                <p class="region" v-if="!flag">{{ provincial }}</p>
                            </div>
                            <div class="users"  @click="Uerron()">
                                <p class="region" v-if="oper">请选择</p>
                                <p class="region" v-if="!oper">{{ city }}</p>
                            </div>
                            <div class="users"  @click="village()">
                                <p class="region" v-if="res">请选择</p>
                                <p class="region" v-if="!res">{{openai}}</p>
                            </div>
                        </div>
                    <p class="name">详细地址</p>
                    <textarea type="text" class="usersum" placeholder="请填写收货人详细地址" v-model="shipping"></textarea>
                    <img src="../../assets/img/onclick.png" alt="" class="React" @click="commit()">
                </div>
           </div>
        </van-popup>
        <div class="Ract">
            <img src="../../assets/img/experience.png" alt="" class="Rideo" >
        </div>
        <div class="Onder">
            <img src="../../assets/img/pigs.png" alt="" class="Pand">
            <img src="../../assets/img/leave.png" alt="" class="User" @click="homepage()">
        </div>
        <div class="Kiode">
            <p class="Orun" @click="download()">APP下载</p>
        </div>
        <van-popup v-model="popup" position="bottom">
            <van-picker
            show-toolbar
            :columns="columns"
            @confirm="onConfirm"
            @cancel="onCancel"
            />
        </van-popup>
        <van-popup v-model="coloder" position="bottom">
            
        </van-popup>
        <van-popup v-model="upopup" position="bottom">
            <van-picker
            show-toolbar
            :columns="columnsm"
            @confirm="onConfirms"
            @cancel="onCancel"
            />
        </van-popup>
        <van-popup v-model="cpopup" position="bottom">
            <van-picker
            show-toolbar
            :columns="columnsl"
            @confirm="onConfirmq"
            @cancel="onCancel"
            />
        </van-popup>
        <van-popup v-model="canpon" style="border-radius: 20px;">
            <div class="Rend">
                <p class="Ranc">登录</p>
                <input type="text" class="Oder"  placeholder="请输入手机号"  v-model="phone2">
                <div class="SUM">
                    <input type="text" class="Oders"  placeholder="请输入验证码"  v-model="username2">
                    <div class="Band" @click="huoqu()">
                        <p v-if="Kand" >获取验证码</p>
                        <p v-if="!Kand">{{ smes }}</p>
                    </div>
                </div>
                <div class="Plan">
                    <div class="denglu" @click="denglu()">
                        <p>登录</p>
                    </div>
                </div>
                <!-- <p class="Uidn">第三方登录</p>
                <div class="cant" @click="weixin()">
                    <img src="../draw/img/weixin.png" alt="" class="Plik">
                </div>
                <p class="fand">微信登录</p> -->
            </div>
        </van-popup>
        <t-captcha-js
            src="https://ssl.captcha.qq.com/TCaptcha.js"
          ></t-captcha-js>
    </div>        
</template>

<script>
import {lucklist,luckname,getProvince,getCityArea,filladdr,codelogin,sendSms,wxlogin} from "../../api/index"

export default{
    components: {
      't-captcha-js': {
        render(createElement) {
          return createElement('script', {
            attrs: { type: 'text/javascript', src: this.src }
          })
        },
        props: {
          src: { type: String, required: true }
        }
      },
    },
    data () {
      return {
        list:[],
        stop:'',
        show: false,
        wx_url:"",
        urlone:'hexiangscheme://host:8888/path',
        name:"",
        canpon:false,
        url:'',
        city:'',
        columnsm:[],
        columnsl:[],
        phone2:'',
        username2:"",
        smes:60,
        Kand:true,
        flag:true,
        oper:true,
        res:true,
        columns:[],
        username:"",
        popup:false,
        phone:"",
        shipping:"",
        regionlist:'',
        none:false,
        piglist:"",
        isandroid:false,
        upopup:false,
        cpopup:false,
        coloder:false,
        openai:'',
        prizes: [],
        pid:'',
        uid:'',
        cid:'',
        qid:"",
        eid:"",
        oid:"",
        appid:"wx3c4f416f1f6b2126",
        isweixn:true,
        rec_id:"",
        prize_type:'',
        provincial:"",
        luckyNum: 0,
        blocks: [{
            padding: '8vw',
            imgs: [{
                src: require('../draw/img/background.png'),
                width: '100%',
                height: '100%'
            }]
        }],
      }
    },
    computed: {
        btnConfig () {
            return {
                x: 1,
                y: 1,
                background: 'rgba(0,0,0,0)',
                shadow: '0 5 1 #e89b4f',
                imgs: [
                { src: require('../draw/img/lottery.png'), width: '100%',  },
                ]
            }
        }
  },
  created() {
    if (this.$route.query.code) {
      this.WxLogin();
    }
  },
    mounted () {
        this.lucklist()
    },
    methods: {
        website(){
            this.$router.push('/friend') 
        },
        download(){
            this.$router.push('/skippage') 
        },
        harvest(){
            this.show = false
            this.none = true
        },
        commit(){
            this.filladdr()
        },
        async codelogin(){
            let data = {
                rid:this.$route.query.rid || '',
                uid:this.$route.query.uid,
                phone:this.phone2,
                keyCode:this.username2,
                store_id:this.$route.query.store_id,
                form:2
            }
            let res = await codelogin(data)
            if(res.data.code==200){
                localStorage.setItem('access_id',res.data.data.access_id)
                this.canpon = false 
                this.$toast("登录成功")
            }else{
                this.$toast(res.data.message)
            }
            // console.log(res)
        },
        weixin(){
           this.wxlogin()
        },
        async wxlogin(){
            let data = {
                code:this.$route.query.code||'',
                callback:location.href,
                rid:this.$route.query.rid||''
            }
            let res = await wxlogin(data)
            if(res.data.code == 200){
                this.wx_url = res.data.data.url
                this.longin()
            }
        },
        async WxLogin(){
            let data = {
                code:this.$route.query.code||'',
                callback:location.href,
                rid:this.$route.query.rid||''
            }       
            let res = await wxlogin(data)
            if(res.data.code == 200){
                localStorage.setItem("access_id", res.data.data.list.access_id);
            }else{
                this.$toast(res.data.message)
            }
            // console.log(res)
        },
        longin(){
            window.location.href = this.wx_url;
        },
        showCodeImgs() {
        // eslint-disable-next-line no-undef
        var captcha1 = new TencentCaptcha('2008794111', (coderes) => {
        //   console.log(coderes)
          const { randstr, ticket } = coderes
          sendSms({ phone: this.phone2, type1: 1, randstr, ticket }).then(
            (res) => {
            //   console.log(res)
              if (res.data.code == 200) {
                    this.daojishi()
                    this.Kand = false
              } else {
                this.$toast(res.data.message)
              }
            }
          )
        })
        captcha1.show()
      },
      denglu(){
        if(this.phone2==''){
            return this.$toast("请输入手机号");
        }else if(this.username2==''){
            return this.$toast("请输入验证码");
        }else{
            this.codelogin()
        }
      },
        async luckname(){
            let data = {
                access_id:localStorage.getItem('access_id')
            }
            let res = await luckname(data)
            if(res.data.code == "1010131"){
                this.canpon = true
                this.$toast(res.data.message);
                this.$refs.LuckDraw.stop()
            }else if(res.data.code == "200"){
                this.$refs.LuckDraw.play()
                setTimeout(() => {
                    this.$refs.LuckDraw.stop( this.stop-1)
                }, 2000)
            }else{
                this.$refs.LuckDraw.stop()
                return alert(res.data.message)
            }
            this.stop = res.data.data.level
            this.url = res.data.data.prize.image
            this.piglist = res.data.data.prize.name
            this.rec_id = res.data.data.rec_id
            this.prize_type = res.data.data.prize.prize_type
        },
        onConfirm(value, index) {
            this.cid = this.uid[index]
            this.provincial = value
            this.flag = false
            this.popup = false
        },
        onConfirms(value,index){
            this.qid = this.pid[index]
            this.city = value
            this.oper = false
            this.upopup = false
        },
        onConfirmq(value,index){
            this.oid = this.eid[index]
            this.openai = value
            this.res = false
            this.cpopup = false
        },
        onCancel() {
           this.popup = false
           this.upopup = false
           this.cpopup = false
        },
        location(){
            this.popup = true
            this.getProvince()
        },
        Uerron(){
            this.upopup = true
            this.getCityArea()
        },
        village(){
            this.cpopup = true
            this.getCityAreae()
        },
        homepage(){
            window.location = "https://d.hxnc.cc/playground?pid=662"
        },
        async filladdr(){
            let data = {
                access_id:localStorage.getItem('access_id'),
                rec_id:this.rec_id,
                user_name:this.name,
                mobile:this.username,
                province_id:this.cid,
                city_id:this.qid,
                area_id:this.oid,
                address:this.shipping
            }
            let res = await filladdr(data)
            if(res.data.code == 200){
                this.none = false
                this.$toast('填写完成');
                this.name = ''
                this.username = ''
                this.provincial =''
                this.city = ''
                this.openai = ''
                this.flag = true
                this.oper = true 
                this.res = true
                this.shipping = ''
            }else{
                this.$toast(res.data.message);
            }
         },
        daojishi(){
        var that = this
              var sass =  setInterval(()=>{
                that.smes--
                    if(that.smes == 0){
                        that.Kand = true
                        that.smes = 60
                        clearInterval(sass)
                    }
                },1000)
        },
        huoqu(){
            this.showCodeImgs()
            // this.daojishi()
            // this.Kand = false
        },
        async getProvince(){
            let data = {
                name:'',
                py:""
            }
            let res = await getProvince(data)
            this.regionlist =  res.data.data.list
            let   listArr = [ ] 
            res.data.data.list.map(item=>{
                listArr.push(item.name)
            })
            this.columns = listArr

            let Userlist = [ ]
            res.data.data.list.map(item=>{
                Userlist.push(item.id)
            })
            this.uid = Userlist
            // console.log( this.uid)
         },
         async getCityArea(){
            let data = {
                pid:this.cid,
                name:'',
                py:""
            }
            let res = await getCityArea(data)
            let   listArr = [ ] 
            res.data.data.list.map(item=>{
                listArr.push(item.name)
            })
            this.columnsm = listArr
            let Userlist = [ ]
            res.data.data.list.map(item=>{
                Userlist.push(item.id)
            })
            this.pid = Userlist
            // console.log(res)
         },
         async getCityAreae(){
            let data = {
                pid:this.qid,
                name:'',
                py:""
            }
            let res = await getCityArea(data)
            let   listArr = [ ] 
            res.data.data.list.map(item=>{
                listArr.push(item.name)
            })
            this.columnsl = listArr
            let Userlist = [ ]
            res.data.data.list.map(item=>{
                Userlist.push(item.id)
            })
            this.eid = Userlist
            // console.log(res)
         },
        async lucklist(){
            let data = {
                access_id:localStorage.getItem('access_id')
            }
            let res = await lucklist(data)
            this.list = res.data.data
            // console.log(this.list,"列表")
            this.prizes = []
            this.luckyNum = 1
            let axis = [[0, 0], [1, 0], [2, 0], [2, 1], [2, 2], [1, 2], [0, 2], [0, 1]]
            for (let i = 0; i < this.list.length; i++) {
            let item =  this.list[i]
            this.prizes.push({
                index: i, x: axis[i][0], y: axis[i][1],
                fonts: [{ text: item.name, top: '72%' }],
                imgs: [{ src: item.image, width: '60%', top: '10%' }]
            })
            // console.log(this.prizes)
          }
        },
        endCallBack (){
            setTimeout(()=>{
                  this.show = true
            },300)
        },
        startCallBack () {
            this.luckname()
        },
    }
}
</script>

<style lang="scss">
    body{
        background-color: #0AAB65;
    }
    .Hander{
        width: 100vw;
        height: 50vh;
        position: relative;

        .User{
            width: 100%;
            height: 100%;
        }

        .Unit{
            width: 300px;
            position: absolute;
            bottom: 60px;
            left: 0;
            right: 0;
            margin: auto;
        }
    }

    .Bottom{
       display: flex;
       justify-content: space-around;
       margin: 30px 0 20px 0 ;
        .Rideo{
            width: 400px;
        }

    }

    .Dome{
        display: flex;
        justify-content: space-around;
    }

    .Ract{
       display: flex;
       justify-content: space-around;
       margin: 30px 0 20px 0 ;
        .Rideo{
            width: 400px;
        }
    }

    .Over{
        display: flex;
        flex-direction: column;
        align-items: center;

        .Grand{
            font-size: 40px;
            color: #FFFFFF;
            margin-bottom: 20px;

            .Grand2{
                font-size: 40px;
                color: #d9e70d;
            }
        }

        .Bander{
            width: 800px;
        }

        .Senter{
            width: 600px;
            height: 600px;
            margin-bottom: 40px;
        }

        .Hiden{
            width: 400px;
        }
    }

    .Onder{
        width: 100%;
        position: relative;
        .Pand{
            width: 100%;
        }

        .User{
            width: 30%;
            position: absolute;
            bottom: 60px;
            left: 0;
            right: 0;
            margin: auto;
        }
    }

    .Kiode{
        width: 100%;
        height: 100px;
        margin-top: 20px;
        background-color: #4FEF60;

        .Orun{
            text-align: center;
            line-height: 100px;
            font-size: 35px;
            font-weight: 600;
            color: #077551;
        }
    }

    .van-picker{
        .van-picker__toolbar{
            padding-top: 30px;
            .van-picker__cancel{
                font-size: 0.4rem;
            }

            .van-picker__confirm{
                font-size: 0.4rem;
            }
        }

        .van-picker__columns{
            .van-ellipsis{
                font-size: 0.4rem;
            }
        }
    }

    .mask-box {
        position: fixed;
        min-height: 100vh;
        background-color: rgba(0,0,0,.5);
        opacity: 1;
        display: block;
        z-index: 999;
        width: 100%;
    }

    .moban{
        display: flex;
        justify-content: flex-end;
    }
    .zhaopian{
        width: 50%;
        z-index: 999;
        margin: 50px;
    }

    .Tizi{

        .Query{
            width: 500px;
            margin: 0;
        }

        .Rsat{
            width: 90%;
            height: 800px;
            padding: 5%;
            background: #FFFFFF;
            margin: 0 ;

            .name{
                font-size: 30px;
                font-weight: 900;
                margin-bottom: 10px;
            }

            .username{
                width: 95%;
                height: 60px;
                padding: 10px;
                border-radius: 13px;	
                outline:none;  	
                border:none;
                background: #F2F2F2;
                margin-bottom: 30px;
            }
            .master{
                display: flex;
                justify-content: space-between;
                .users{
                    width: 130px;
                    height: 60px;
                    border-radius: 13px;
                    background: #F2F2F2;

                    .region{
                        text-align: center;
                        line-height: 60px;
                        color: #6e6d6d;
                    }
                }
            }

            .usersum{
                width: 95%;
                height: 120px;
                padding: 10px;
                border-radius: 13px;	
                outline:none;  	
                border:none;
                background: #F2F2F2;
                margin-bottom: 30px;
            }

            .React{
                width: 95%;
                height: 100px;
            }
        }
    }

    .Rend{
        width: 500px;
        height: 500px;
        padding: 40px;

        .Ranc{
            font-size: 34px;
            font-weight: 600;
        }

        .Oder{
            width: 90%;
            height: 50px;
            border-radius: 40px;
            background: #F2F2F2;
            margin-top: 30px;
            padding: 20px;
            outline:none; 
            border:none;
        }

        .Oders{
            width: 50%;
            height: 50px;
            border-radius: 40px;
            background: #F2F2F2;
            margin-top: 30px;
            padding: 20px;
            outline:none; 
            border:none;
        }

        .Band{
            width: 30%;
            height: 50px;
            border-radius: 40px;
            padding: 20px;
            background: #0AAB65;
            text-align: center;
            line-height: 50px;
            color: #FFFFFF;
            margin-left: 20px;
        }
    }   

    .SUM{
        display: flex;
        align-items: flex-end;
    }

    .Plan{
        display: flex;
        justify-content: space-around;
    }

    .denglu{
        width: 92%;
        height: 100px;
        background: #0AAB65;
        border-radius: 60px;
        text-align: center;
        line-height: 100px;
        color: #FFFFFF;
        margin-top: 50px;
    }
    .Uidn{
        text-align: center;
        margin-top: 20px;
        color: #999999;
    }

    .Plik{
        width: 100px;
    }

    .cant{
        display: flex;
        justify-content: space-around;
        margin-top: 20px;
    }

    .fand{
        text-align: center;
        margin-top: 20px;
    }
</style>